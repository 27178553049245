
import { Component, Prop, Mixins } from 'vue-property-decorator';
import BestAthletesLogo from '@/components/ui/BestAthletesLogo.vue';
import { HinderRouteMetadata } from '../../router/router';
import { VuetifyMixin } from '../../mixins';

@Component({
	components: { BestAthletesLogo },
})
export default class AppBar extends Mixins(VuetifyMixin){
	@Prop({ type: Boolean, default: false }) collapseSideNav;
	@Prop({ type: Boolean, default: true }) showSideNav;

	get RouteMetadata(): HinderRouteMetadata{
		return this.$route.meta;
	}
	get HideAppBar(): boolean{
		return this.RouteMetadata.hideAppBar === true && !(this.RouteMetadata.showOnMobile === true && this.$vuetify.breakpoint.xs);
	}
	get IsCollapsed(): boolean{
		return this.collapseSideNav;
	}
	get HideTitle(): boolean{
		return this.$vuetify.breakpoint.xsOnly;
	}
	get NoTitle(): boolean{
		return this.RouteMetadata.showOnMobile === true && this.$vuetify.breakpoint.xs;
	}

	updateShowSideNav(): void{
		this.showSideNav = !this.showSideNav
		this.$emit('update:show-side-nav', this.showSideNav);
	}

	goBack(): void{
		if(this.RouteMetadata.onClickTitle !== undefined){
			this.RouteMetadata.onClickTitle.apply(this);
		}else{
			window.history.back();
		}
	}

	get ShowBackButton(): boolean{
		return this.RouteMetadata.useBackButton === true;
	}
	get PageTitle(): string{
		if(this.RouteMetadata.pageTitle){
			return this.RouteMetadata.pageTitle;
		}
		return this.$route.name;
	}

	get Color(): string{
		if(this.IsMobile){
			return this.getColor('baColorDeepBlue');
		}
		return this.getColor('baColorAppForeground');
	}
}
