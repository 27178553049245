
import Vue from 'vue';
import PublicCoachSideNav from '../components/navigation/PublicCoachSideNav.vue';
import PublicAppBar from '../components/navigation/PublicAppBar.vue';
import Footer from '../components/navigation/Footer.vue';

export default Vue.extend({
	components: { PublicAppBar, PublicCoachSideNav, Footer },
	data(){
		return {
			showSideNav: true,
		};
	},
	created(){
		if(this.CollapseSideNav) this.showSideNav = false;
	},
	computed: {
		CollapseSideNav(){
			return this.$vuetify.breakpoint.mobile;
		},
		ShowSystemBar(): boolean{
			return this.$slots['system-bar'] !== undefined;
		},
		UseFullscreen(): boolean{
			return this.$route.meta.useFullscreen ===  true;
		},
	}
});
